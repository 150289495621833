<template>
	<div class="partial partial--registrations registrations">
		<div class="partial__heading">
			<ion-icon name="file-tray-full-outline" size="large" class="partial__icon"></ion-icon>
			<h1 class="partial__title">Manage Registrations</h1>
		</div>

		<form class="form partial__form" :class="{'partial__form--visible':formVisible}" @submit.prevent="submitForm(fields)" autocomplete="off">
			<button class="button partial__form-search" @click="formVisible=!formVisible">
				<ion-icon name="search-outline" class="mdc-button__icon" aria-hidden="true"></ion-icon>
				<span class="mdc-button__label">Search</span>
			</button>
			<div class="form-section">
				<div class="form-row form-row--col-4">
					<text-field class="form__field" label="Name" v-model="fields.name" type="text" autocomplete="off">
						<span slot="pre"><ion-icon name="search-outline"></ion-icon></span>
					</text-field>
					<text-field class="form__field" label="Email" v-model="fields.email" type="text" autocomplete="off">
						<span slot="pre"><ion-icon name="search-outline"></ion-icon></span>
					</text-field>
					<!-- <text-field class="form__field" label="Company" v-model="fields.company" type="text" autocomplete="off">
						<span slot="pre"><ion-icon name="search-outline"></ion-icon></span>
					</text-field> -->
					<autocomplete-organization class="form__field" :value="fields.orgId" @selected="orgSelect" autocomplete="off" />

					<!-- <autocomplete-organization @selected="orgSelect" /> -->
					<!-- <autocomplete-field class="form__field" label="Organization" @input="orgSearch" v-model="fields.orgSearch" :options="orgOptions" @select="orgSelect" autocomplete="off" /> -->
					<input type="hidden" v-model="fields.orgId" />
					<div>
						<button type="submit" class="button button--unelevated form__button">Search</button>
						<button type="button" class="button form__button button--primary" @click="reset()">clear</button>
					</div>
				</div>
			</div>
		</form>

		<div class="partial__body registrations__body">
			<list-registrations :registrations="registrations" :loading="loading" />
			<pagination :limit="limit" :count="regCount" />
		</div>

		<modal @close="hideModal" :open="modalVisible" :title="modalTitle">
			<router-view class="main__modal" slot="content" name="pageModal" />
		</modal>
	</div>
</template>

<script>
import AutocompleteOrganization from '@/components/AutocompleteOrganization'
import AutocompleteField from '@/components/AutocompleteField'
import ListRegistrations from '@/components/ListRegistrations'
import Modal from '@/components/Modal'
import Pagination from '@/components/Pagination'
import SelectField from '@/components/SelectField'
import TextField from '@/components/TextField'
import { RegistrationStatus } from '@/lib/enums'

export default {
	name: 'PartialRegistrations',
	components: {
		AutocompleteOrganization,
		AutocompleteField,
		ListRegistrations,
		Modal,
		Pagination,
		SelectField,
		TextField,
	},
	metaInfo: () => ({
		title: 'Registrations',
	}),
	data: () => ({
		fields: {
			orgId: undefined,
			orgSearch: undefined,
		},
		formVisible: false,
		fromQuery: null,
		orgOptions: [],
		orgLoading: false,
		limit: 25,
	}),
	computed: {
		loading() {
			return this.$store.getters['misc/loading']
		},
		modalVisible() {
			return this.$route.name == 'registration'
		},
		modalTitle() {
			return this.$route.meta.modalTitle
		},
		organizations() {
			const orgs = this.$store.getters['organization/organizations']
			return orgs.map(o => { return { value: o.orgId, label: o.name} } )
		},
		registrations() {
			return this.$store.getters['registration/registrations']
		},
		regCount() {
			return this.registrations.length
		},
	},
	methods: {
		hideModal(clicked) {
			this.$router.replace({ name: 'registrations', query: this.fromQuery })
		},
		async orgSearch(value) {
			if (!value || value.length < 4) return ''
			// this.orgLoading = true
			const results = await this.$store.dispatch('registration/list', { key: 'keywords', op: 'array-contains', value })
			this.orgOptions = results.map(r => { return { value: r.orgId, label: r.name }})
			// this.orgLoading = false
		},
		orgSelect(selected) {
			this.$set(this.fields, 'orgId', selected.value)
			this.$set(this.fields, 'orgSearch', selected.label)
		},
		async submitForm(fields) {
			const _keys = []
			const _vals = []
			for (const key in fields) {
				if (fields[key] && key != 'orgSearch') {
					_keys.push(`keywords.${key}`)
					_vals.push(fields[key])
				}
			}

			let keys = _keys.join(`,`)
			let vals = _vals.join(`,`)

			try {
				if (keys && vals) {
					this.$router.push({ query: { key: keys, op: 'array-contains', value: vals }})
				} else {
					this.$router.push({ query: {}})
				}
			} catch (error) {
				this.$notice(`ERROR: ${error.message || error}`)

			}

		},
		reset() {
			this.fields = {}
			if (this.$route.query.key || this.$route.query.orderBy) {
				this.submitForm(this.fields)
			}
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler: function (to, from) {
				if (from && from.name == 'registrations' && to.name == 'registration') return this.fromQuery = from.query
				if (from && from.name == 'registration'
					&& JSON.stringify(to.query) == JSON.stringify(this.fromQuery)
				) return

				this.$store.dispatch('registration/list', {...to.query, p: to.query.p || 1, limit: this.limit })
					.catch(error => {
						this.$notice(`ERROR: ${error.message || error}`)
					})

				if (to.query?.key) {
					let _keys = to.query.key?.split(',') || []
					let _vals = to.query.value?.split(',') || []
					_keys.forEach((key, i) => this.fields[key.replace(/keywords\./i,'')] = _vals[i])
				}
			},
		},
	},

}
</script>

<style scoped lang="scss">

</style>
